* {
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
}

/* fonts */
@font-face {
  font-family: "nexa-light"; /* You can name it whatever you want */
  src: url("/fonts/Nexa-ExtraLight.ttf") format("ttf");
  /* Add more formats if needed */
  font-weight: light;
  font-style: light;
}
@font-face {
  font-family: "nexa-bold"; /* You can name it whatever you want */
  src: url("../pages/fonts/GE_SS_Two_Bold.otf");
  /* Add more formats if needed
  font-weight: bold;
  font-style: bold; */
}

/** Responsiveness **/

/*! xl, extra-large: 1536px*/ /*! ....... */
/* @media only screen and (max-width: 1920px) { */
.horizontal-safe-margin {
  margin-right: 148px;
  margin-left: 148px;
}
.horizontal-safe-padding {
  padding-right: 148px;
  padding-left: 148px;
}
.vertical-safe-margin {
  margin-top: 60px;
  margin-bottom: 60px;
}
.vertical-safe-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}
.section-safe-padding-top {
  padding-top: 60px;
}
.section-safe-padding-bottom {
  padding-bottom: 60px;
}
.section-safe-padding-left {
  padding-left: 148px;
}
.section-safe-padding-right {
  padding-right: 148px;
}
.section-safe-margin-top {
  margin-top: 60px;
}
.section-safe-margin-bottom {
  margin-bottom: 60px;
}
.section-safe-margin-right {
  margin-right: 148px;
}
.section-safe-margin-left {
  margin-left: 148px;
}
/* } */
@media only screen and (max-width: 1440px) {
  .horizontal-safe-margin {
    margin-right: 80px;
    margin-left: 80px;
  }
  .horizontal-safe-padding {
    padding-right: 80px;
    padding-left: 80px;
  }
  .vertical-safe-margin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .vertical-safe-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section-safe-padding-top {
    padding-top: 60px;
  }
  .section-safe-padding-bottom {
    padding-bottom: 60px;
  }
  .section-safe-padding-left {
    padding-left: 80px;
  }
  .section-safe-padding-right {
    padding-right: 80px;
  }
  .section-safe-margin-top {
    margin-top: 60px;
  }
  .section-safe-margin-bottom {
    margin-bottom: 60px;
  }
  .section-safe-margin-right {
    margin-right: 80px;
  }
  .section-safe-margin-left {
    margin-left: 80px;
  }
}
/*! lg, large: 1200px */
@media only screen and (max-width: 1200px) {
  .horizontal-safe-margin {
    margin-right: 65px;
    margin-left: 65px;
  }
  .horizontal-safe-padding {
    padding-right: 65px;
    padding-left: 65px;
  }
  .vertical-safe-margin {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .vertical-safe-padding {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .section-safe-padding-top {
    padding-top: 45px;
  }
  .section-safe-padding-bottom {
    padding-bottom: 45px;
  }
  .section-safe-padding-left {
    padding-left: 65px;
  }
  .section-safe-padding-right {
    padding-right: 65px;
  }
  .section-safe-margin-top {
    margin-top: 45px;
  }
  .section-safe-margin-bottom {
    margin-bottom: 45px;
  }
  .section-safe-margin-right {
    margin-right: 65px;
  }
  .section-safe-margin-left {
    margin-left: 65px;
  }
}
/*! md, medium: 900px */
@media only screen and (max-width: 900px) {
  .horizontal-safe-margin {
    margin-right: 40px;
    margin-left: 40px;
  }
  .horizontal-safe-padding {
    padding-right: 40px;
    padding-left: 40px;
  }
  .vertical-safe-margin {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .vertical-safe-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .section-safe-padding-top {
    padding-top: 30px;
  }
  .section-safe-padding-bottom {
    padding-bottom: 30px;
  }
  .section-safe-padding-left {
    padding-left: 40px;
  }
  .section-safe-padding-right {
    padding-right: 40px;
  }
  .section-safe-margin-top {
    margin-top: 30px;
  }
  .section-safe-margin-bottom {
    margin-bottom: 30px;
  }
  .section-safe-margin-right {
    margin-right: 40px;
  }
  .section-safe-margin-left {
    margin-left: 40px;
  }
}
/*! sm, small: 600px */ /* xs, extra-small: 0px*/
@media only screen and (max-width: 600px) {
  .horizontal-safe-margin {
    margin-right: 16px;
    margin-left: 16px;
  }
  .horizontal-safe-padding {
    padding-right: 16px;
    padding-left: 16px;
  }
  .vertical-safe-margin {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .vertical-safe-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .section-safe-padding-top {
    padding-top: 15px;
  }
  .section-safe-padding-bottom {
    padding-bottom: 16px;
  }
  .section-safe-padding-left {
    padding-left: 16px;
  }
  .section-safe-padding-right {
    padding-right: 16px;
  }
  .section-safe-margin-top {
    margin-top: 16px;
  }
  .section-safe-margin-bottom {
    margin-bottom: 16px;
  }
  .section-safe-margin-right {
    margin-right: 16px;
  }
  .section-safe-margin-left {
    margin-left: 16px;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* font-family: GE_SS_Light !important; */
  /* font-family: Almarai !important; */
  /* font-family: nexa-bold !important; */
}

html,
body {
  /* max-width: 100vw; */
  /* overflow: hidden; */
}

.carousel-indicators button {
  height: 6px !important;
  background-color: #fcb72e !important;
}
.carousel-indicators .active {
  background-color: #fcb72e !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #2d317f;
  border: 1px solid #2d317f;
}

.MuiSvgIcon-root {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  clip-path: circle(50% at 50% 50%);
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root,
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-4ff9q7 {
  color: #afb0b8 !important;
  font-size: 25px;
  font-size: 42px;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root,
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.css-4ff9q7 {
  color: #f18222 !important;
}
.MuiStepConnector-root.MuiStepConnector-vertical.Mui-active.css-1pe7n21-MuiStepConnector-root
  .MuiStepConnector-line.MuiStepConnector-lineVertical.css-8t49rw-MuiStepConnector-line {
  border-color: #f18222;
}
.css-1pe7n21-MuiStepConnector-root,
.css-52tf1x.MuiStepConnector-root {
  margin-left: 20px !important;
}
/* **** */
.pick
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root,
.pick
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.css-4ff9q7 {
  color: #fbb735 !important;
}
.pick
  .MuiStepConnector-root.MuiStepConnector-vertical.Mui-active.css-1pe7n21-MuiStepConnector-root
  .pick
  .MuiStepConnector-line.MuiStepConnector-lineVertical.css-8t49rw-MuiStepConnector-line {
  border-color: #fbb735;
}
/* =========loading========= */

.box-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: transparent;
  margin: auto;
}

.loader {
  --loader-size: 40px;
  --loader-border-size: 3px;
  --loader-border-color: #f18222;
  width: var(--loader-size);
  height: var(--loader-size);
  border: var(--loader-border-size) solid var(--loader-border-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: rotateX 1s infinite linear;
}

.loader::before {
  content: "";
  border: var(--loader-border-size) solid var(--loader-border-color);
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  animation: rotateX 0.5s infinite linear reverse;
}

@keyframes rotateX {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.stepper-box .css-rko9yk-MuiButtonBase-root-MuiStepButton-root,
.stepper-box .css-105xk4f {
  position: unset !important;
}

.MuiTouchRipple-root {
  display: none;
}

.carousel-control-prev-icon {
  background-image: url(/arr-l.svg);
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
  padding: 10px !important;
}
.carousel-control-next-icon {
  background-image: url(/arr-r.svg);
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
  padding: 10px !important;
}

.acc-pick .MuiAccordionSummary-root,
.acc-pick .MuiAccordionSummary-content {
  padding: 0 !important;
  margin: 0 !important;
}
.acc-pick .MuiPaper-root {
  box-shadow: none !important;
}
.acc-pick::before {
  display: none !important;
}
.acc-pick {
  padding: 0 !important;
  margin: 0 !important;
  padding-bottom: 12px !important;
}
.acc-pick .MuiAccordionSummary-root {
  min-height: 0px !important;
}
.acc-pick p {
  font-size: 14px !important;
  font-weight: bold !important;
}

.acc-pick .MuiAccordionSummary-root {
  justify-content: flex-start !important;
  gap: 5px !important;
}
.acc-pick .MuiAccordionSummary-content {
  flex-grow: 0 !important;
}
.acc-pick .MuiAccordionDetails-root {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.company-home .MuiPaper-root.MuiPaper-elevation {
  border-radius: 16px !important;
  box-shadow: 0px 6px 12px 0px #0409212e;
  margin-bottom: 20px !important;
}

.company-home .MuiPaper-root.Mui-expanded {
  border: 1px solid #2d317f !important;
}
.company-home .MuiPaper-root.Mui-expanded .container-pick {
  background-color: #2d317f;
}
.company-home .MuiPaper-root.Mui-expanded .arrow-pick {
  color: #fff;
}
/* .company-home .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before { */
.company-home ::before {
  display: none !important;
}
.company-home .swiper-slide {
  display: flex;
  justify-content: center;
}
.carousel-indicators.rtl {
  right: 0;
  left: auto;
  flex-direction: row-reverse;
}
.carousel.rtl-carousel .carousel-indicators {
  flex-direction: row-reverse;
}

/* swap controls for rtl */
.rtl-carousel .carousel-control-prev {
  right: 0;
  left: auto;
}

.rtl-carousel .carousel-control-next {
  left: 0;
  right: auto;
}
/* flip the icon for rtl  */
.carousel.rtl-carousel .carousel-control-prev-icon {
  transform: rotate(180deg);
}

.carousel.rtl-carousel .carousel-control-next-icon {
  transform: rotate(180deg);
}
